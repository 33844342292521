import { API_BASE_URL, JSON_CONTENT_TYPE, JSON_MERGE_PATCH_CONTENT_TYPE, requestHeaders, handleResponseErrors } from "./common";

class ListApi {
    static getList(id) {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/lists/" + id, {
            method: "GET",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static addList(groupId, name) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/groups/" + groupId + "/lists", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ name: name })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static updateList(listId, draft) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/lists/" + listId, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify({ draft: draft })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static addItem(listId, name, store, url, notes) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/lists/" + listId + "/items", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ name: name, store: store, url: url, notes: notes })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static updateItem(id, name, store, url, notes) {
        const headers = requestHeaders(JSON_MERGE_PATCH_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/items/" + id, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify({ name: name, store: store, url: url, notes: notes })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static moveItem(id, ordinal) {
        const headers = requestHeaders(JSON_MERGE_PATCH_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/items/" + id, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify({ ordinal: ordinal })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static reserveItem(id) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/items/" + id, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ action: "RESERVE" })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static purchaseItem(id) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/items/" + id, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ action: "PURCHASE" })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static deleteItem(id) {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/items/" + id, {
            method: "DELETE",
            headers: headers
        }).then(handleResponseErrors);
    }
}

export default ListApi;
