import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { isReservedByMe, isPurchasedByMe } from "../../predicates";

const ReserveButton = ({ item, onClick }) => (
    <button type="button" className={ classNames("btn", { "btn-outline-primary": !isReservedByMe(item), "btn-primary": isReservedByMe(item) }) } onClick={ onClick }>
        <span className="oi oi-tag" title={ isReservedByMe(item) ? "Undo Reservation" : "Reserve" } aria-hidden="true"></span>
    </button>
);

class PurchaseButton extends React.Component {
    constructor() {
        super();

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if(isPurchasedByMe(this.props.item)) {
            if(!window.confirm("You marked this item as purchased, are you sure you want to undo this?"))
                return;
        }

        this.props.onClick();
    }

    render() {
        const item = this.props.item;

        return (
            <button type="button" className={ classNames("btn", { "btn-outline-primary": !isPurchasedByMe(item), "btn-primary": isPurchasedByMe(item) }) } onClick={ this.handleClick }>
                <span className="oi oi-cart" title={ isPurchasedByMe(item) ? "Undo Purchase" : "Purchase" } aria-hidden="true"></span>
            </button>
        );
    }
}

PurchaseButton.propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func
};

const DeleteButton = ({ onClick }) => (
    <button type="button" className="btn btn-outline-primary" onClick={ onClick }>
        <span className="oi oi-trash" title="Delete" aria-hidden="true"></span>
    </button>
);

export { ReserveButton, PurchaseButton, DeleteButton };
