import React from "react";
import PropTypes from "prop-types";

class AuthCallback extends React.Component {
    componentDidMount() {
        this.props.auth.handleAuthentication(this.props);
    }

    render() {
        return <span>Loading...</span>;
    }
}

AuthCallback.propTypes = {
    auth: PropTypes.object
};

export default AuthCallback;
