import { API_BASE_URL, JSON_CONTENT_TYPE, requestHeaders, handleResponseErrors } from "./common";

class GroupApi {
    static getGroups() {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/groups", {
            method: "GET",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static getGroup(id) {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/groups/" + id, {
            method: "GET",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static inviteGroupMember(groupId, firstName, lastName, emailAddress) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/groups/" + groupId + "/invitations", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ firstName: firstName, lastName: lastName, emailAddress: emailAddress })
        }).then(handleResponseErrors).then(response => response.json());
    }

    static getGroupPurchasesSummary(id) {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/groups/" + id + "/purchases/summary", {
            method: "GET",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static getInvitation(id) {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/invitations/" + id, {
            method: "GET",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static acceptInvitation(id) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/invitations/" + id, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ action: "ACCEPT" })
        }).then(handleResponseErrors).then(response => response.json());
    }
}

export default GroupApi;
