import React from "react";
import { createRoot } from 'react-dom/client';
import { Router, Route, Redirect } from "react-router-dom";

import App from "./components/App";
import LoginPage from "./components/login/LoginPage";
import LoginFailedPage from "./components/login/LoginFailedPage";
import HomePage from "./components/home/HomePage";
import GroupPage from "./components/groups/GroupPage";
import ListPage from "./components/lists/ListPage";
import InvitationPage from "./components/invitations/InvitationPage";
import AuthCallback from "./components/AuthCallback";  // XXX <- include here ?

import history from "./history";
import auth from "./Auth";

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route { ...rest } render={ props => (
    auth.isAuthenticated() ? (
      <Component auth={ auth } { ...props } />
    ) : (
      <Redirect to={ {
        pathname: "/",
        state: { from: props.location }
      } } />
    )
  ) } />
);

// TODO: Is this router structure correct?
const routes =
    <Router history={ history }>
        <App auth={ auth }>
            <Route path="/" exact render={ (props) => (auth.isAuthenticated() ? ( <HomePage auth={ auth } { ...props } /> ) : (<LoginPage auth={ auth } { ...props } /> ) ) } />
            <Route path="/invitations/:invitationId" exact render={ (props) => <InvitationPage auth={ auth } { ...props } /> } />

            <AuthenticatedRoute path="/groups/:groupId" exact component={ GroupPage } />
            <AuthenticatedRoute path="/lists/:listId" exact component={ ListPage } />

            <Route path="/auth0/callback" exact render={ (props) => <AuthCallback auth={ auth } { ...props } /> } />
            <Route path="/login-failed" exact component={ LoginFailedPage } />
        </App>
    </Router>;

const root = createRoot(document.getElementById("root"));

root.render(routes);
