import { getAccessToken } from "../Auth";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "/api";

const JSON_CONTENT_TYPE = "application/json";
const JSON_MERGE_PATCH_CONTENT_TYPE = "application/merge-patch+json";

function handleResponseErrors(response) {
    if(!response.ok)
        throw Error(response.statusText);

    return response;
}

function requestHeaders(contentType, accessToken = null) {
    const headers = {
        "Authorization": "Bearer " + (accessToken || getAccessToken())
    };

    if(contentType)
        headers["Content-Type"] = contentType;
    
    return headers;
}

export { API_BASE_URL, JSON_CONTENT_TYPE, JSON_MERGE_PATCH_CONTENT_TYPE, requestHeaders, handleResponseErrors };
