import React from "react";

class Artwork extends React.Component {
    constructor() {
        super();

        this.state = {
            number: Math.floor((Math.random() * 8) + 1)
        };
    }

    render() {
        const { number } = this.state;

        return (
            <img src={`/images/artwork/${number}.jpg`} width="40%" alt="" className="mt-3" />
        );
    }
};

export default Artwork;
