import React from "react";
import PropTypes from "prop-types";

import Header from "./common/Header";
import Footer from "./common/Footer";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: this.props.auth.getCurrentUser()
        };

        this.props.auth.onCurrentUserChanged = this.handleCurrentUserChanged.bind(this);
    }

    getChildContext() {
        return {
            currentUser: this.state.currentUser
        };
    }

    handleCurrentUserChanged(user) {
        this.setState({ currentUser: user });
    }

    handleLogout() {
        this.props.auth.logout();
    }

    render() {
        return (
            <div>
                <Header currentUser={ this.state.currentUser } onLogout={ () => this.handleLogout() } />

                { this.props.children }
            
                <Footer />
            </div>
        );
    }
}

App.propTypes = {
    auth: PropTypes.object,
    children: PropTypes.node
};

App.childContextTypes = {
    currentUser: PropTypes.object
};

export default App;
