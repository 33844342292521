import React from "react";
import PropTypes from "prop-types";

import ListApi from "../../api/ListApi";
import history from "../../history";

const moment = window.moment;

const Summary = ({ member, currentUser }) => {
    let message;

    if(member.type === "invitation") {
        message = "Was invited " + moment(member.invitation.createdDateTime).fromNow();

    } else if(member.lists.length === 0) {
        if(member.id === currentUser.id)
            message = "You haven't started your list yet";
        else
            message = "Hasn't started their list yet";
    } else {
        return null;
    }

    return <p className="card-text">{ message }</p>;
};

const Actions = ({ member, currentUser, onStartListClick, onRemindClick }) => {
    if(member.type === "invitation") {
        return <button className="btn btn-link text-secondary p-0" onClick={ onRemindClick }>Remind { member.firstName }</button>;
    } else if(member.lists.length === 0) {
        if(member.id === currentUser.id)
            return <button className="btn btn-success" onClick={ onStartListClick }>Start My List</button>;
        else
            return <button className="btn btn-link text-secondary p-0" onClick={ onRemindClick }>Remind { member.firstName }</button>;
    } 

    return null;
};

class MemberCard extends React.Component {
    constructor() {
        super();

        this.handleStartListClick = this.handleStartListClick.bind(this);
        this.handleRemindClick = this.handleRemindClick.bind(this);
    }

    handleStartListClick() {
        const groupId = this.props.groupId;

        ListApi.addList(groupId, null).then(list => {
            history.replace("/lists/" + list.id);
        });
    }

    handleRemindClick() {
        alert("TODO");
    }

    render() {
        const member = this.props.member;

        const currentUser = this.context.currentUser;

        return (
            <div className="card mb-3">
                <div className="card-body">
                    <h4 className="card-title">{ member.name }</h4>

                    <Summary member={ member } currentUser={ currentUser } />

                    <Actions member={ member } currentUser={ currentUser } onStartListClick={ this.handleStartListClick } onRemindClick={ this.handleRemindClick } />
                </div>
            </div>
        );
    }
}

MemberCard.propTypes = {
    groupId: PropTypes.any,
    member: PropTypes.object
};

MemberCard.contextTypes = {
    currentUser: PropTypes.object
};

export default MemberCard;
