import React from "react";

const Header = ({ currentUser, onLogout }) => (
    <div className="navbar navbar-dark bg-dark">
        <span className="navbar-brand mb-0">Santa's Little Helper</span>

        { currentUser !== null && <div>
            <span className="navbar-text d-none d-md-inline mr-3">Hi { currentUser.firstName }</span>

            <button className="btn btn-outline-primary" onClick={ onLogout }>Log Out</button>
        </div> }
    </div>
);

export default Header;
