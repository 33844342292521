import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";

import ListApi from "../../api/ListApi";

const $ = window.$;

class ListItemModal extends React.Component {
    constructor(props) {
        super(props);

        // TODO: move this so that item is loaded from API - only pass ID
        const item = props.item;

        // TODO: move this into state... it is used in many places
        const isEdit = !R.isNil(item);

        this.state = {
            name: isEdit ? item.name : "",
            store: isEdit ? (item.store || "") : "",
            url : isEdit ? (item.url || "") : "",
            notes : isEdit ? (item.notes || "") : "",
            form: {
                name: {
                    touched: false,
                    valid: isEdit ? true : null
                },
                store: {
                    touched: false
                },
                url: {
                    touched: false
                },
                notes: {
                    touched: false
                }
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const element = $(ReactDOM.findDOMNode(this));

        element.on("hidden.bs.modal", this.props.onHide);

        element.modal("show");

        $("#name").focus();
    }

    validateField(name, value) {
        let valid = null;

        if(name === "name")
            valid = !R.isEmpty(R.trim(value));

        const form = R.set(R.lensPath([name, "valid"]), valid, this.state.form);

        this.setState({ form: form });
    }

    handleChange(event) {
        const { name, value } = event.target;

        this.setState({ [name]: value }, () => this.validateField(name, value));
    }

    handleBlur(event) {
        const name = event.target.name;

        if(this.state.form[name].touched)
            return;

        const form = R.set(R.lensPath([name, "touched"]), true, this.state.form);

        this.setState({ form: form });
    }

    handleSubmit(event) {
        event.preventDefault();

        const isEdit = !R.isNil(this.props.item);

        const name = R.trim(this.state.name);
        const store = R.trim(this.state.store);
        const url = R.trim(this.state.url);

        if(isEdit) {
            ListApi.updateItem(this.props.item.id, name, store, url, this.state.notes).then(item => {
                $(ReactDOM.findDOMNode(this)).modal("hide");

                this.props.onItemUpdated(item);
            });
        } else {
            ListApi.addItem(this.props.listId, name, store, url, this.state.notes).then(item => {
                $(ReactDOM.findDOMNode(this)).modal("hide");

                this.props.onItemAdded(item);
            });
        }
    }

    render() {
        const isEdit = !R.isNil(this.props.item);

        const form = this.state.form;

        const isFormValid = form.name.valid;

        return (
            <div className="modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form onSubmit={ this.handleSubmit }>
                            <div className="modal-header">
                                <h5 className="modal-title">{ isEdit ? "Edit" : "Add" } Item</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name">Item</label>
                                    <input name="name" type="text" value={ this.state.name } maxLength="100" className={ classNames("form-control", { "is-invalid": form.name.touched && !form.name.valid }) } id="name" placeholder="Item" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="store">Store</label>
                                    <input name="store" type="text" value={ this.state.store } maxLength="100" className="form-control" id="store" placeholder="Store" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="url">Website</label>
                                    <input name="url" type="text" value={ this.state.url } className="form-control" id="url" placeholder="www.example.com" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="notes">Notes</label>
                                    <textarea name="notes" value={ this.state.notes } className="form-control" id="notes" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <input type="submit" value={ isEdit ? "Update" : "Add" } className="btn btn-primary" disabled={ !isFormValid } />
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

ListItemModal.propTypes = {
    listId: PropTypes.any,
    item: PropTypes.object,
    onItemAdded: PropTypes.func,
    onItemUpdated: PropTypes.func,
    onHide: PropTypes.func
};

export default ListItemModal;
