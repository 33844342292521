import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

import ListApi from "../../api/ListApi";
import { isPersonalList, isProxyList, isListOwnedBy, isItemAvailable, isReservedByMe, isPurchasedByMe } from "../../predicates";

import { ReserveButton, PurchaseButton } from "../common/ListItemButtons";

const ListCardListItem = ({ item, onPurchaseClick, onReserveClick }) => (
    <li className={ classNames("list-group-item", { "bg-warning": item.reservation, "bg-success": item.purchase }) }>
        { item.name }

        <div className="btn-group btn-group-sm float-right" role="group">
            { item.canPurchase && <PurchaseButton item={ item } onClick={ onPurchaseClick } /> }
            { item.canReserve && <ReserveButton item={ item } onClick={ onReserveClick } /> }
        </div>
    </li>
);

const ListCardItems = ({ items, onReserveClick, onPurchaseClick }) => {
    const myReservedItems = items.filter(isReservedByMe);
    const availableItems = R.sortBy(item => item.ordinal, items.filter(isItemAvailable));
    const myPurchasedItems = items.filter(isPurchasedByMe);

    return (
        <ul className="list-group list-group-flush">
            { myReservedItems.concat(R.take(3, availableItems)).concat(myPurchasedItems).map(item => <ListCardListItem key={ item.id } item={ item } onReserveClick={ () => onReserveClick(item.id) } onPurchaseClick={ () => onPurchaseClick(item.id) } />) }
        </ul>
    );
};

const Badges = ({ items }) => {
    const purchasedCount = items.filter(isPurchasedByMe).length;
    const reservedCount = items.filter(isReservedByMe).length;

    if(purchasedCount === 0 && reservedCount === 0)
        return null;

    return (
        <div>
            { purchasedCount > 0 && <span className="badge badge-success"><strong>{ purchasedCount }</strong> purchased</span> }
            { reservedCount > 0 && <span className="badge badge-warning"><strong>{ reservedCount }</strong> reserved</span> }
        </div>
    );
};

const Summary = ({ list, items, currentUser }) => {
    let message;

    const isMyList = isListOwnedBy(currentUser.id);
    const isMyPersonalList = R.allPass([isMyList, isPersonalList]);
    const isMyProxyList = R.allPass([isMyList, isProxyList]);

    if(list.draft && !isMyList(list)) {
        message = "Is working on their list";
    } else if(list.isEmpty) {
        if(isMyPersonalList(list))
            message = "Your list is empty, add some items";
        else if(isMyProxyList(list))
            message = "List is empty, add some items";
        else
            message = "List is empty, let them know";
    } else if(list.draft && isMyList(list)) {
        message = (isMyPersonalList ? "Your" : "Their") + " list is in draft state, don't take too long";
    } else if(isMyPersonalList(list)) {
        const count = items.length;

        message = "You have " + count + " " + (count === 1 ? "item" : "items") + " on your list";
    } else {
        const count = items.filter(isItemAvailable).length;

        if(count === 0)
            message = "All items have been purchased or reserved, wow!";
        else if(count === 1)
            message = "Only one item remaining";
        else if(count <= 5)  // TODO: Should this be based on the number of group members?
            message = "Only " + count + " items remaining";
        else
            message = "There are " + count + " items remaining";
    }
    
    return <p className="card-text">{ message }</p>;
};

const Actions = ({ list, items, currentUser}) => {
    const isMyPersonalList = R.allPass([isListOwnedBy(currentUser.id), isPersonalList]);
    const isMyProxyList = R.allPass([isListOwnedBy(currentUser.id), isProxyList]);

    if(isMyPersonalList(list))
        return <Link to={ "/lists/" + list.id }>Open My List</Link>;
    else if(isMyProxyList(list) || (!list.draft && !R.isEmpty(items)))
        return <Link to={ "/lists/" + list.id }>Open { list.type === "Personal" ? list.user.firstName : list.name }'s List</Link>;

    return null;
};

class ListCard extends React.Component {
    constructor() {
        super();

        this.state = {
            items: null,
            isLoading: true
        };

        this.handleReserveClick = this.handleReserveClick.bind(this);
        this.handlePurchaseClick = this.handlePurchaseClick.bind(this);
    }

    componentDidMount() {
        const listId = this.props.list.id;

        ListApi.getList(listId).then(list => {
            this.setState({
                items: list.items,
                isLoading: false
            });
        });
    }

    handleReserveClick(itemId) {
        ListApi.reserveItem(itemId).then(item => {
            this.updateItem(item);

            this.props.onListUpdated();
        });
    }

    handlePurchaseClick(itemId) {
        ListApi.purchaseItem(itemId).then(item => {
            this.updateItem(item);

            this.props.onListUpdated();
        });
    }

    // TODO: should this just be turned into a lens and then use R.set in the call-site?
    updateItem(item) {
        const itemIndex = this.state.items.findIndex(R.propEq(item.id, "id"));

        const items = R.update(itemIndex, item, this.state.items);

        this.setState({ items: items });
    }

    render() {
        const list = this.props.list;
        const items = this.state.items;

        const currentUser = this.context.currentUser;

        const isLoading = this.state.isLoading;

        const isMyPersonalList = R.allPass([isListOwnedBy(currentUser.id), isPersonalList]);

        const showListItems = !isLoading && !isMyPersonalList(list) && !list.draft && !R.isEmpty(items);

        return (
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-8">
                            <h4 className="card-title">{ list.name }</h4>
                        </div>

                        { showListItems && <div className="col-4 text-right"><Badges items={ items } /></div> }
                    </div>

                    { list.type === "Proxy" && <h6 className="card-subtitle mb-3 text-muted">List managed by { list.user.id === currentUser.id ? "you" : list.user.name }</h6> }

                    { isLoading && <div><span>Loading...</span></div> }

                    { !isLoading && <Summary list={ list } items={ items } currentUser={ currentUser } /> }

                    { !showListItems && <Actions list={ list } items={ items } currentUser={ currentUser } /> }
                </div>

                { showListItems && <ListCardItems items={ items } onReserveClick={ this.handleReserveClick } onPurchaseClick={ this.handlePurchaseClick } /> }

                { showListItems && <div className="card-body">
                    <Actions list={ list } items={ items } currentUser={ currentUser } />
                </div> }
            </div>
        );
    }
}

ListCard.propTypes = {
    listId: PropTypes.any,
    onListUpdated: PropTypes.func
};

ListCard.contextTypes = {
    currentUser: PropTypes.object
};

export default ListCard;
