import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";

import GroupApi from "../../api/GroupApi";

const $ = window.$;
const is = window.is;

class InviteGroupMemberModal extends React.Component {
    constructor() {
        super();

        this.state = {
            firstName: "",
            lastName: "",
            emailAddress: "",
            form: {
                firstName: {
                    touched: false,
                    valid: null
                },
                lastName: {
                    touched: false,
                    valid: null
                },
                emailAddress: {
                    touched: false,
                    valid: null
                }
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const element = $(ReactDOM.findDOMNode(this));

        element.on("hidden.bs.modal", this.props.onHide);

        element.modal("show");

        $("#first-name").focus();
    }

    validateField(name, value) {
        let valid = null;

        if(name === "firstName")
            valid = !R.isEmpty(R.trim(value));
        else if(name === "emailAddress")
            valid = is.email(value);

        const form = R.set(R.lensPath([name, "valid"]), valid, this.state.form);

        this.setState({ form: form });
    }

    handleChange(event) {
        const { name, value } = event.target;

        this.setState({ [name]: value }, () => this.validateField(name, value));
    }

    handleBlur(event) {
        const name = event.target.name;

        if(this.state.form[name].touched)
            return;

        const form = R.set(R.lensPath([name, "touched"]), true, this.state.form);

        this.setState({ form: form });
    }

    handleSubmit(event) {
        event.preventDefault();

        // TODO: just doing the add here - is that correct or should this component
        // just allow the parent to do the API call?  By doing it here we can make
        // sure the validation is done here and the event is only trigger on success.

        const { firstName, lastName, emailAddress } = this.state;

        // TODO: should we assert form is valid here as opposed to just assuming it is
        //       because the button was disabled?

        GroupApi.inviteGroupMember(this.props.groupId, R.trim(firstName), R.trim(lastName), R.trim(emailAddress)).then(member => {
            $(ReactDOM.findDOMNode(this)).modal("hide");

            this.props.onMemberAdded(member);
        });
    }

    render() {
        const form = this.state.form;

        const isFormValid = form.firstName.valid && form.emailAddress.valid;

        return (
            <div className="modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form onSubmit={ this.handleSubmit }>
                            <div className="modal-header">
                                <h5 className="modal-title">Invite Someone</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="first-name">First Name</label>
                                        <input name="firstName" type="text" value={ this.state.firstName } maxLength="50" className={ classNames("form-control", { "is-invalid": form.firstName.touched && !form.firstName.valid }) } id="first-name" placeholder="First" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="last-name">Last Name</label>
                                        <input name="lastName" type="text" value={ this.state.lastName } maxLength="50" className={ classNames("form-control") } id="last-name" placeholder="Last" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email-address">Email Address</label>
                                    <input name="emailAddress" type="email" value={ this.state.emailAddress } maxLength="100" className={ classNames("form-control", { "is-invalid": form.emailAddress.touched && !form.emailAddress.valid }) } id="email-address" placeholder="Email" onChange={ this.handleChange } onBlur={ this.handleBlur } />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <input type="submit" value="Invite" className="btn btn-primary" disabled={ !isFormValid } />
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

InviteGroupMemberModal.propTypes = {
    groupId: PropTypes.any,
    onMemberAdded: PropTypes.func,
    onHide: PropTypes.func
};

export default InviteGroupMemberModal;
