import { API_BASE_URL, JSON_CONTENT_TYPE, requestHeaders, handleResponseErrors } from "./common";

class InvitationApi {
    static getInvitation(id) {
        const headers = requestHeaders(null);

        return fetch(API_BASE_URL + "/invitations/" + id, {
            method: "GET",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static acceptInvitation(id) {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/invitations/" + id, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ action: "ACCEPT" })
        }).then(handleResponseErrors).then(response => response.json());
    }
}

export default InvitationApi;
