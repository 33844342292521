import * as R from "ramda";

function summarizeListOfNames(names, limit) {
    const count = names.length;

    if(count <= limit)
        return R.take(count < 2 ? count : count - 1, names).join(", ") + (count - 1 > 0 ? (" and " + names[count - 1]) : "");

    const remaining = count - limit;

    return R.take(limit, names).join(", ") + " and " + remaining + " " + (remaining === 1 ? "other" : "others");
}

export { summarizeListOfNames };
