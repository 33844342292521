import * as R from "ramda";

const isPersonalList = R.propEq("Personal", "type");
const isProxyList = R.propEq("Proxy", "type");

const isListOwnedBy = R.curry((userId, list) => list.user.id === userId);

const isItemAvailable = (item) => R.isNil(item.reservation) && R.isNil(item.purchase);

// TODO: this is a hack to avoid having to depend on currentUser and without adding
// something to the API
const isReservedByMe = (item) => !R.isNil(item.reservation) && item.canReserve;

const isPurchasedByMe = (item) => !R.isNil(item.purchase) && item.canPurchase;

export { isPersonalList, isProxyList, isListOwnedBy, isItemAvailable, isReservedByMe, isPurchasedByMe };
