import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import parse from "url-parse";

import ListApi from "../../api/ListApi";
import { isPersonalList, isListOwnedBy, isReservedByMe, isPurchasedByMe } from "../../predicates";

import ListItemModal from "./ListItemModal";
import { ReserveButton, PurchaseButton, DeleteButton } from "../common/ListItemButtons";
import Artwork from "../common/Artwork";

function normalizeUrl(url) {
    if(R.isNil(url))
        return null;

    if(/^(f|ht)tps?:\/\//i.test(url))
        return url;

    return "http://" + url;
}

const DragHandle = SortableHandle(() => <span className="oi oi-elevator"></span>);

const ItemRow = SortableElement(({ item, sortable, onPurchaseClick, onReserveClick, onEditClick, onDeleteClick }) => {
    const url = normalizeUrl(item.url);

    let fromLabel = null;

    if(!R.isNil(item.store)) {
        fromLabel = item.store;
    } else if(!R.isNil(item.url)) {
        const parsedUrl = parse(url, "");

        fromLabel = parsedUrl.hostname;
    }

    let from = null;

    if(fromLabel !== null) {
        if(url !== null)
            from = <i className="text-muted">from <a href={ url } target="_blank">{ fromLabel }</a></i>;
        else
            from = <i className="text-muted">from { fromLabel }</i>;
    }

    let badge = null;

    if(item.reservation)
        badge = <span className="badge badge-warning">Reserved { item.reservation.user && <span>by { item.reservation.user.name }</span> }</span>;
    else if(item.purchase)
        badge = <span className="badge badge-success">Purchased { item.purchase.user && <span>by { item.purchase.user.name }</span> }</span>;

    return (
        <tr>
            { sortable && <td><DragHandle /></td> }
            <td>
                { item.name } { from }
                { badge && <div>{ badge }</div> }
                { item.notes && <p className="small text-muted mt-2 mb-0">{ item.notes }</p> }
            </td>
            <td>
                <div className="btn-group btn-group-sm float-right" role="group">
                    { item.canPurchase && <PurchaseButton item={ item } onClick={ onPurchaseClick } /> }
                    { item.canReserve && <ReserveButton item={ item } onClick={ onReserveClick } /> }
                    { item.canEdit && <button type="button" className="btn btn-outline-primary" onClick={ onEditClick }><span className="oi oi-pencil" title="Edit" aria-hidden="true"></span></button> }
                    { item.canDelete && <DeleteButton item={ item } onClick={ onDeleteClick } /> }
                </div>
            </td>
        </tr>
    );
});

const ItemsTableBody = SortableContainer(({ items, sortable, onPurchaseClick, onReserveClick, onEditClick, onDeleteClick }) => (
    <tbody>
        { items.map((item, index) => <ItemRow key={ item.id } index={ index } item={ item } sortable={ sortable } onPurchaseClick={ () => onPurchaseClick(item.id) } onReserveClick={ () => onReserveClick(item.id) } onEditClick={ () => onEditClick(item.id) } onDeleteClick={ () => onDeleteClick(item.id) } />) }
    </tbody>
));

const ItemsTable = ({ list, sortable, currentUser, ...rest }) => {
    // TODO: cannot sort by ordinal until ordinals are re-caculated on MOVE
    const items = sortable ? /*R.sortBy(item => item.ordinal, list.items)*/ list.items : R.sortWith([R.ascend(R.partial(getItemSortGroup, [currentUser])), R.ascend(R.prop("ordinal"))], list.items);

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    { sortable && <th></th> }
                    <th>Item</th>
                    <th></th>
                </tr>
            </thead>

            <ItemsTableBody items={ items } sortable={ sortable } useDragHandle={ true } { ...rest } />
        </table>
    );
};

const Draft = ({ list, currentUser, onDraftCompleteClick }) => {
    const isMyPersonalList = R.allPass([isListOwnedBy(currentUser.id), isPersonalList]);

    return (
        <div className="alert alert-warning" role="alert">
            <h4 className="alert-heading">Draft list</h4>
            <p>{ isMyPersonalList(list) ? "Your" : list.name + "'s" } list is in a draft state, other users will not see or be able to purchase any items until you complete the list.</p>
            <hr />
            <button className="btn btn-outline btn-success" onClick={ onDraftCompleteClick }>Complete</button>
        </div>
    );
};

const EmptyList = ({ list, currentUser }) => {
    const isMyPersonalList = R.allPass([isListOwnedBy(currentUser.id), isPersonalList]);

    return (
        <div className="alert alert-success" role="alert">
            { isMyPersonalList(list) ? "Your" : list.name + "'s" } list is empty, add some items
        </div>
    );
};

class ListPage extends React.Component {
    constructor() {
        super();

        this.state = {
            list: null,
            isLoading: true,
            showListItemModal: false,
            modalItem: null
        };

        this.handleDraftCompleteClick = this.handleDraftCompleteClick.bind(this);
        this.handlePurchaseClick = this.handlePurchaseClick.bind(this);
        this.handleReserveClick = this.handleReserveClick.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
    }

    componentDidMount() {
        const listId = this.props.match.params.listId;

        ListApi.getList(listId).then(list => {
            this.setState({
                list: list,
                isLoading: false
            });
        });
    }

    handleDraftCompleteClick() {
        if(!window.confirm("Are you sure?"))
            return;

        const listId = this.props.match.params.listId;

        const draft = false;

        ListApi.updateList(listId, draft).then(list => {
            this.setState({
                list: list
            });
        });
    }

    handleSortEnd(sort) {
        const { oldIndex, newIndex } = sort;

        const item = this.state.list.items[oldIndex];

        // Move the item to the new index before making the API call - if it fails then move the item
        // back to the original position.  Note that the item ordinals will no longer be accurate after
        // the move but this does not matter for now.
        const list = R.over(R.lensProp("items"), R.pipe(R.reject(R.propEq(item.id, "id")), R.insert(newIndex, item)), this.state.list);

        this.setState({ list: list });

        ListApi.moveItem(item.id, newIndex + 1).catch(error => {
            const list = R.over(R.lensProp("items"), R.pipe(R.reject(R.propEq(item.id, "id")), R.insert(oldIndex, item)), this.state.list);

            this.setState({ list: list });
        });
    }

    handleAddItemClick() {
        if(!this.state.list.draft)
            window.alert("If you add an item to a non-draft list you will not be able to edit or delete the item after it has been saved.");

        this.setState({ showListItemModal: true, modalItem: null });
    }

    handleListItemModalClosed() {
        this.setState({ showListItemModal: false, modalItem: null });
    }

    handleItemAdded(item) {
        const list = R.over(R.lensProp("items"), R.append(item), this.state.list);

        this.setState({ list: list });
    }

    handleItemUpdated(item) {
        this.updateItem(item);
    }

    handleEditClick(itemId) {
        const item = this.state.list.items.find(R.propEq(itemId, "id"));

        this.setState({ showListItemModal: true, modalItem: item });
    }

    handleReserveClick(itemId) {
        ListApi.reserveItem(itemId).then(item => {
            this.updateItem(item);
        });
    }

    handlePurchaseClick(itemId) {
        ListApi.purchaseItem(itemId).then(item => {
            this.updateItem(item);
        });
    }

    handleDeleteClick(itemId) {
        ListApi.deleteItem(itemId).then(item => {
            const itemIndex = this.state.list.items.findIndex(R.propEq(itemId, "id"));

            const list = R.over(R.lensProp("items"), R.remove(itemIndex, 1), this.state.list);

            this.setState({ list: list });
        });
    }

    // TODO: should this just be a lens and then use R.set at the call-site?
    updateItem(item) {
        const itemIndex = this.state.list.items.findIndex(R.propEq(item.id, "id"));

        const list = R.set(R.lensPath(["items", itemIndex]), item, this.state.list);

        this.setState({ list: list });
    }


    render() {
        const list = this.state.list;

        const currentUser = this.context.currentUser;

        const isLoading = this.state.isLoading;

        const isMyList = !isLoading && (list.user.id === currentUser.id);
        const isEmpty = !isLoading && R.isEmpty(list.items);

        return (
            <div>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    { !isLoading && <li className="breadcrumb-item"><Link to={ "/groups/" + list.group.id }>{ list.group.name }</Link></li> }
                    { !isLoading && <li className="breadcrumb-item active">{ list.name }</li> }
                </ol>

                <div className="container-fluid">
                    { isLoading && <span>Loading...</span> }

                    { !isLoading && <div className="row justify-content-center">
                        <div className="col-md-6">
                            { isEmpty && <EmptyList list={ list } currentUser={ currentUser } /> }

                            { isMyList && list.draft && !isEmpty && <Draft list={ list } currentUser={ currentUser } onDraftCompleteClick={ this.handleDraftCompleteClick } /> }

                            { !isEmpty && <ItemsTable list={ list } sortable={ isMyList } currentUser={ currentUser } onPurchaseClick={ this.handlePurchaseClick } onReserveClick={ this.handleReserveClick } onEditClick={ this.handleEditClick } onDeleteClick={ this.handleDeleteClick } onSortEnd={ this.handleSortEnd } /> }
                        </div>
                    </div> }

                    { !isLoading && list.canAddItem && <div className="row">
                        <div className="col text-center">
                            <button className="btn btn-outline-primary" onClick={ () => this.handleAddItemClick() }>Add Item</button>
                        </div>
                    </div> }

                    <div className="row">
                        <div className="col text-center">
                            <Artwork />
                        </div>
                    </div>

                    { this.state.showListItemModal && <ListItemModal listId={ list.id } item={ this.state.modalItem } onHide={ () => this.handleListItemModalClosed() } onItemAdded={ (item) => this.handleItemAdded(item) } onItemUpdated={ (item) => this.handleItemUpdated(item) } /> }
                </div>
            </div>
        );
    }
}

ListPage.propTypes = {
    match: PropTypes.object
};

ListPage.contextTypes = {
    currentUser: PropTypes.object
};

function getItemSortGroup(currentUser, item) {
    if(isReservedByMe(item))
        return 1;

    if(!(item.reservation || item.purchase))
        return 2;

    if(item.reservation)
        return 3;

    if(isPurchasedByMe(item))
        return 4;

    return 5;
}

export default ListPage;
