import React from "react";

import Groups from "./Groups";
import GroupApi from "../../api/GroupApi";

class HomePage extends React.Component {
    constructor() {
        super();

        this.state = {
            groups: null,
            isLoading: true
        };
    }

    componentDidMount() {
        GroupApi.getGroups().then(groups => {
            this.setState({
                groups: groups,
                isLoading: false
            });
        });
    }

    render() {
        const groups = this.state.groups;

        const isLoading = this.state.isLoading;

        return (
            <div>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Home</li>
                </ol>

                <div className="container-fluid">
                    { isLoading && <span>Loading...</span> }

                    { !isLoading && <Groups groups={ groups } /> }
                </div>
            </div>
        );
    }
}

export default HomePage;
