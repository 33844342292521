import React from "react";

const Footer = () => (
    <footer className="footer">
        <div className="container-fluid">
            <a href="https://git.sr.ht/~ajk/santas-little-helper" target="_new">About</a>
        </div>
    </footer>
);

export default Footer;
