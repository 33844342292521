import React from "react";

class LoginFailedPage extends React.Component {
    render() {
        return (
            <div className="jumbotron jumbotron-fluid">
                <div className="container">
                    <h1 className="display-3 text-danger">Error</h1>

                    <p className="lead">Login failed.</p>
                </div>
            </div>
        );
    }
}

export default LoginFailedPage;
