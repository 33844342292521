import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import MemberCard from "./MemberCard";
import ListCard from "./ListCard";
import InviteGroupMemberModal from "./InviteGroupMemberModal";
import Artwork from "../common/Artwork";

import GroupApi from "../../api/GroupApi";

import { isPersonalList, isProxyList, isListOwnedBy } from "../../predicates";
import { summarizeListOfNames } from "../../utility";

const Summary = ({ purchasedFrom, notPurchasedFrom }) => {
    if(R.isEmpty(purchasedFrom) && R.isEmpty(notPurchasedFrom))
        return null;

    return (
        <div className="alert alert-primary d-none d-md-block" role="alert">
            { !R.isEmpty(purchasedFrom) && <p className="mb-0">You have bought gifts for { summarizeListOfNames(R.pluck("name", R.sortBy(R.prop("name"), purchasedFrom)), 3) }.</p> }

            { !R.isEmpty(notPurchasedFrom) && <p className="mb-0">You still need to buy a gift for { summarizeListOfNames(R.pluck("name", R.sortBy(R.prop("name"), notPurchasedFrom)), 3) }.</p> }
        </div>
    );
};

const Cards = ({ group, currentUser, onListUpdated }) => {
    const myNoListCard = group.members.filter(member => member.type === "user" && member.id === currentUser.id && R.isEmpty(member.lists)).map(member => <MemberCard groupId={ group.id } member={ member } />);
    const listCards = R.sortWith([R.ascend(R.partial(getListSortGroup, [currentUser])), R.ascend(R.prop("name"))], group.lists).map(list => <ListCard list={ list } onListUpdated={ onListUpdated } />);
    const noListCards = group.members.filter(member => member.type === "user" && member.id !== currentUser.id && R.isEmpty(member.lists)).map(member => <MemberCard groupId={ group.id } member={ member } />);
    const invitationCards = group.members.filter(member => member.type === "invitation").map(member => <MemberCard groupId={ group.id } member={ member } />);

    const cards = myNoListCard.concat(listCards).concat(noListCards).concat(invitationCards);

    return (
        <div>
            { R.splitEvery(3, cards).map((row, rowIndex) =>
                <div key={ "row-" + rowIndex } className="row">
                    { row.map((card, columnIndex) => <div key={ "row-" + rowIndex + "-col-" + columnIndex } className="col-md-4">{ card }</div>) }
                </div>
            )}
        </div>
    );
};

class GroupPage extends React.Component {
    constructor() {
        super();

        this.state = {
            group: null,
            isLoadingGroup: true,
            summary: null,
            isLoadingSummary: true,
            showInviteGroupMemberModal: false
        };

        this.handleListUpdated = this.handleListUpdated.bind(this);
    }
    
    componentDidMount() {
        const groupId = this.props.match.params.groupId;

        GroupApi.getGroup(groupId).then(group => {
            this.setState({
                group: group,
                isLoadingGroup: false
            });
        });

        this.loadPurchasesSummary();
    }

    loadPurchasesSummary() {
        const groupId = this.props.match.params.groupId;

        GroupApi.getGroupPurchasesSummary(groupId).then(summary => {
            this.setState({
                summary: summary,
                isLoadingSummary: false
            });
        });
    }

    handleListUpdated() {
        this.loadPurchasesSummary();
    }

    handleInviteGroupMemberClick() {
        this.setState({ showInviteGroupMemberModal: true });
    }

    handleInviteGroupMemberModalClosed() {
        this.setState({ showInviteGroupMemberModal: false });
    }

    handleMemberAdded(member) {
        const group = R.over(R.lensProp("members"), R.append(member), this.state.group);

        this.setState({ group: group });
    }

    render() {
        const group = this.state.group;

        const currentUser = this.context.currentUser;

        const isLoadingGroup = this.state.isLoadingGroup;

        return (
            <div>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    { !isLoadingGroup && <li className="breadcrumb-item active">{ group.name }</li> }
                </ol>

                <div className="container-fluid">
                    { !this.state.isLoadingSummary && <Summary purchasedFrom={ this.state.summary.purchasedFrom } notPurchasedFrom={ this.state.summary.notPurchasedFrom } /> }

                    { isLoadingGroup && <span>Loading...</span> }

                    { !isLoadingGroup && <div>
                        <Cards group={ group } currentUser={ currentUser } onListUpdated={ this.handleListUpdated } />

                        <div className="row">
                            <div className="col text-center">
                                <button className="btn btn-outline-primary" onClick={ () => this.handleInviteGroupMemberClick() }>Invite Someone</button>
                            </div>
                        </div>
                    </div> }

                    <div className="row">
                        <div className="col text-center">
                            <Artwork />
                        </div>
                    </div>

                    { this.state.showInviteGroupMemberModal && <InviteGroupMemberModal groupId={ group.id } onHide={ () => this.handleInviteGroupMemberModalClosed() } onMemberAdded={ (member) => this.handleMemberAdded(member) } /> }
                </div>
            </div>
        );
    }
}

GroupPage.propTypes = {
    match: PropTypes.object
};

GroupPage.contextTypes = {
    currentUser: PropTypes.object
};

function getListSortGroup(currentUser, list) {
    const isMyList = isListOwnedBy(currentUser.id);

    // my personal draft first
    if(isMyList(list) && isPersonalList(list) && list.draft)
        return 1;
    
    // my proxy drafts next
    if(isMyList(list) && isProxyList(list) && list.draft)
        return 2;
    
    // other's lists (including my proxies but not my personal)
    if(!isMyList(list) || (isMyList(list) && isProxyList(list)))
        return 3;
    
    // my personal list
    return 4;
}

export default GroupPage;
