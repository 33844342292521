import React from "react";
import { Link } from "react-router-dom";

const Groups = ({ groups }) => (
    <ol>
        { groups.map(group => <li key={ group.id }><Link to={ "/groups/" + group.id }>{ group.name }</Link></li>) }
    </ol>
);

export default Groups;
