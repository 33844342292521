import React from "react";
import PropTypes from "prop-types";

class LoginPage extends React.Component {
    login() {
        this.props.auth.login(null);
    }

    render() {
        return (
            <div>
                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h1 className="display-3">Hi</h1>

                        <p className="lead">Welcome to Santa's Little Helper... please login by clicking the button below.</p>

                        <button className="btn btn-primary" onClick={ () => this.login() }>Log In</button>
                    </div>
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    auth: PropTypes.object
};

export default LoginPage;
