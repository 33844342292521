import queryString from "query-string";

import history from "./history";

import SessionApi from "./api/SessionApi";
import InvitationApi from "./api/InvitationApi";

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = "slh.accessToken";
const ACCESS_TOKEN_EXPIRES_LOCAL_STORAGE_KEY = "slh.accessTokenExpires";
const SESSION_ID_LOCAL_STORAGE_KEY = "slh.sessionId";
const CURRENT_USER_LOCAL_STORAGE_KEY = "slh.currentUser";

class Auth2 {
    onCurrentUserChanged = null;

    auth0 = new window.auth0.WebAuth({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        responseType: "token id_token",
        audience: "slh-api",
        scope: "openid profile",
    });

    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }

    login(invitationId) {
        let options = { };

        if(invitationId !== null) {
            const uri = this.auth0.baseOptions.redirectUri + "?invitationId=" + invitationId;

            options = { redirectUri: uri };
        }

        this.auth0.authorize(options);
    }

    logout() {
        SessionApi.logout().then(() => {
            this.clearLocalStorageItems();

            //
            if(this.onCurrentUserChanged !== null)
                this.onCurrentUserChanged(null);
            //

            history.replace("/");
        });
    }

    handleAuthentication(props) {
        const location = props.location;

        this.auth0.parseHash((error, result) => {
            if(result && result.accessToken && result.idToken) {
                // Parse the URL query string to get the invitiation if included.
                const parameters = queryString.parse(location.search);

                const invitationId = parameters.invitationId !== undefined ? parameters.invitationId : null;

                SessionApi.login(result.accessToken, invitationId).then(user => {
                    const accessTokenExpires = new Date().getTime() + (result.expiresIn * 1000);

                    this.setLocalStorageItems(result.accessToken, accessTokenExpires, null, user);

                    //
                    if(this.onCurrentUserChanged !== null)
                        this.onCurrentUserChanged(user);
                    //

                    if(invitationId != null) {
                        InvitationApi.acceptInvitation(invitationId).then(invitation => {
                            history.replace("/groups/" + invitation.group.id);
                        });
                    } else {
                        history.replace("/");
                    }
                }).catch(error => {
                    history.replace("/login-failed");
                });
            } else if(error) {
                console.log("handleAuthentication", error);

                history.replace("/login-failed");
            }
        });
    }

    isAuthenticated() {
        const accessTokenExpires = localStorage.getItem(ACCESS_TOKEN_EXPIRES_LOCAL_STORAGE_KEY);

        if(accessTokenExpires === null)
            return false;

        return new Date().getTime() < parseInt(accessTokenExpires, 10);
    }

    clearLocalStorageItems() {
        localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(ACCESS_TOKEN_EXPIRES_LOCAL_STORAGE_KEY);
        localStorage.removeItem(SESSION_ID_LOCAL_STORAGE_KEY);
        localStorage.removeItem(CURRENT_USER_LOCAL_STORAGE_KEY);
    }

    setLocalStorageItems(accessToken, accessTokenExpires, sessionId, currentUser) {
        localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
        localStorage.setItem(ACCESS_TOKEN_EXPIRES_LOCAL_STORAGE_KEY, accessTokenExpires);
        localStorage.setItem(CURRENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(currentUser));
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY));
    }
}

const auth = new Auth2();

function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
}

export { auth as default, getAccessToken };
