import React from "react";
import PropTypes from "prop-types";

import history from "../../history";

import InvitationApi from "../../api/InvitationApi";

class InvitationPage extends React.Component {
    constructor() {
        super();

        this.state = {
            invitation: null,
        };
    }

    componentDidMount() {
        const invitationId = this.props.match.params.invitationId;

        InvitationApi.getInvitation(invitationId).then(invitation => {
            // If the invitation has already been accepted then redirect to the home
            // page (which may force the user to login).
            if(invitation.acceptedDateTime !== null)
                history.replace("/");

            this.setState({
                invitation: invitation,
            });
        });
    }

    onAcceptClick() {
        const invitation = this.state.invitation;

        const isAuthenticated = this.props.auth.isAuthenticated();

        if(isAuthenticated) {
            InvitationApi.acceptInvitation(invitation.id).then(invitation => {
                history.replace("/groups/" + invitation.group.id);
            });
        } else {
            this.props.auth.login(invitation.id);
        }
    }

    render() {
        const invitation = this.state.invitation;

        if(invitation == null)
            return <span>Loading...</span>;

        const isAuthenticated = this.props.auth.isAuthenticated();

        let firstName = invitation.firstName;

        if(isAuthenticated)
            firstName = this.context.currentUser.firstName;

        const buttonText = isAuthenticated ? "Accept" : "Log In and Accept";

        return (
            <div>
                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h1 className="display-3">Hi { firstName }</h1>

                        <p className="lead">You have been invited to { invitation.group.name }.</p>

                        <button className="btn btn-success" onClick={ () => this.onAcceptClick() }>{ buttonText }</button>
                    </div>
                </div>
            </div>
        );
    }
}

InvitationPage.propTypes = {
    auth: PropTypes.object,
    match: PropTypes.object
};

InvitationPage.contextTypes = {
    currentUser: PropTypes.object
};

export default InvitationPage;
