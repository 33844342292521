import { API_BASE_URL, JSON_CONTENT_TYPE, requestHeaders, handleResponseErrors } from "./common";

class SessionApi {
    static login(accessToken, invitationId) {
        const headers = requestHeaders(JSON_CONTENT_TYPE, accessToken);

        return fetch(API_BASE_URL + "/login" + (invitationId !== null ? "?invitationId=" + invitationId : ""), {
            method: "POST",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }

    static logout() {
        const headers = requestHeaders(JSON_CONTENT_TYPE);

        return fetch(API_BASE_URL + "/logout", {
            method: "POST",
            headers: headers
        }).then(handleResponseErrors).then(response => response.json());
    }
}

export default SessionApi;
